<template>
	<main>
		<JobFilterSelection
			:selected="!jobRecommendation ? getJobFilter : undefined"
			:job-fields="jobFields"
			:get-possible-filter="userJobFilterStore.getPossibleFilter"
			:user="user"
			@set-job-filter="EventBus.$emit('set-job-filter', $event)"
			@update="$emit('update', $event)"
		>
			<template #default="slotProps">
				<template v-if="slotProps.loading">Bitte warten...</template>
				<template v-else>
					<template v-if="!slotProps.possibleResults || slotProps.possibleResults <= 0">
						Fertig
					</template>
					<template v-else> {{ slotProps.possibleResults }} Jobs verfügbar </template>
				</template>
			</template>
		</JobFilterSelection>
	</main>
</template>

<script lang="ts">
import type { IAPIJobFieldTree, IAPIJobFilter, IAPILoginUser } from '@hokify/common';
import JobFilterSelection from '@hokify/shared-components-nuxt3/lib/components/JobFilterSelection.vue';
import { mapStores } from 'pinia';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { useUserJobFilterStore } from '@/stores/user-job-filter';
import { useUserProfileStore } from '@/stores/user-profile';
import { useValuesStore } from '@/stores/values';
import { defineNuxtComponent, definePageMeta, useSeoMeta } from '#imports';

export default defineNuxtComponent({
	setup() {
		definePageMeta({
			layout: 'user',
			middleware: ['auth']
		});

		useSeoMeta({
			title: 'Jobfilter | hokify'
		});
	},
	components: {
		JobFilterSelection
	},
	emits: ['update'],
	async asyncData({ $pinia }) {
		const valuesStore = useValuesStore($pinia);
		const jobFields = await valuesStore.getJobFields();
		return { jobFields };
	},
	data() {
		const jobFields = undefined as undefined | IAPIJobFieldTree[];
		return { EventBus, jobFields };
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		getJobFilter(): IAPIJobFilter | undefined {
			return this.userProfileStore.obj?.jobFilter;
		},
		...mapStores(useUserJobFilterStore, useUserProfileStore)
	},
	props: {
		jobRecommendation: { type: Boolean, default: false }
	}
});
</script>
